import React, { useState } from 'react';
import Modal from './Modal'; // Asegúrate de importar el componente Modal correctamente
import { FaCheckCircle, FaExclamationCircle, FaQuestionCircle } from 'react-icons/fa';
import { axiosInstance } from '../AxiosConfig';
import '../styles/ModalPassword.scss';

function ModalPassword({ onClose, email }) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    // Function to validate the structure of the password
    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
        return regex.test(password);
    };

    // Handling changes in the new password input
    const handleNewPasswordChange = (event) => {
        const { value } = event.target;
        setNewPassword(value);
        setPasswordError(!validatePassword(value));
        if (confirmPassword.length > 0) {
            setConfirmPasswordError(value !== confirmPassword);
        }
    };

    // Handling changes in the confirm password input
    const handleConfirmPasswordChange = (event) => {
        const { value } = event.target;
        setConfirmPassword(value);
        setConfirmPasswordError(value !== newPassword);
    };

    // Function to change the password
    const handleChangePassword = async () => {
        if (!passwordError && !confirmPasswordError) {
            try {

                const response = await axiosInstance.post('/api/UserData/UpdateUserPassword', {
                    Usuario: email,
                    ContrasenaActual: currentPassword,
                    NuevaContrasena: newPassword
                });

                switch (response.status) {
                    case 200: // OK
                        setModalContent({
                            icon: <FaCheckCircle className='iconos-modales' />,
                            title: "Contraseña actualizada correctamente",
                            message: "Tu contraseña ha sido actualizada correctamente.",
                            redirectUrl: '/login'
                        });
                        break;
                    case 404: // NotFound
                        setModalContent({
                            icon: <FaExclamationCircle className='iconos-modales' />,
                            title: "Usuario no encontrado",
                            message: "No se encontró un usuario con ese correo electrónico."
                        });
                        break;
                    case 400: // BadRequest
                        setModalContent({
                            icon: <FaExclamationCircle className='iconos-modales' />,
                            title: "Error al cambiar la contraseña",
                            message: response.data
                        });
                        break;
                    default:
                        setModalContent({
                            icon: <FaExclamationCircle className='iconos-modales' />,
                            title: "Error desconocido",
                            message: "Ocurrió un error desconocido. Por favor, intenta de nuevo más tarde."
                        });
                        break;
                }
                setShowModal(true);
            } catch (error) {
                console.error("Error cambiando la contraseña:", error);
                setModalContent({
                    icon: <FaExclamationCircle className='iconos-modales' />,
                    title: "Error de conexión",
                    message: "No se pudo conectar con el servidor. Por favor, verifica tu conexión a internet."
                });
                setShowModal(true);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        onClose();
    };

    const showTooltip = () => {
        setIsTooltipVisible(true);
    };

    const hideTooltip = () => {
        setIsTooltipVisible(false);
    };

    return (
        <div className="modal modal-password" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <FaExclamationCircle style={{ marginRight: "10px", fontSize: '24px' }} />
                        <h5 className="modal-title">Change Password</h5>
                    </div>
                    <div className="modal-body">
                        <p>Por temas de seguridad es necesario que cambies la contraseña que te entrega el sistema por defecto, la cual la recibes vía correo electrónico, y definas una nueva siguiendo los protocolos de seguridad sugeridos.</p>
                        <input
                            type="password"
                            className="form-control mb-3"
                            placeholder="Ingresa la contraseña anterior"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            className={`form-control mb-1 ${passwordError ? 'is-invalid' : ''}`}
                            placeholder="Ingresa la nueva contraseña"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                        />
                        <input
                            type="password"
                            className={`form-control mt-2 ${confirmPasswordError ? 'is-invalid' : ''}`}
                            placeholder="Confirma contraseña nueva"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <div className="tooltip-section">
                            <span className="tooltip-text">Requisitos para definir contraseña:</span>
                            <FaQuestionCircle
                                onMouseEnter={showTooltip}
                                onMouseLeave={hideTooltip}
                                className="tooltip-icon"
                            />
                            {isTooltipVisible && (
                                <div className="tooltip-custom">
                                    <ul>
                                        <li>Debe tener al menos 1 carácter en mayúscula</li>
                                        <li>Debe contener al menos un carácter numérico</li>
                                        <li>Debe contener al menos un carácter especial (@,$,!,%,*,&,?)</li>
                                        <li>Debe contener mínimo un tamaño de 8 caracteres</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        {passwordError && (
                            <div className="invalid-feedback d-block">
                                La contraseña no cumple con los requisitos necesarios.
                            </div>
                        )}
                        {confirmPasswordError && (
                            <div className="invalid-feedback d-block">
                                Las contraseñas no coinciden.
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary btn-sm" onClick={handleCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-primary btn-sm" onClick={handleChangePassword} disabled={passwordError || confirmPasswordError}>Guardar cambios</button>
                    </div>
                </div>
            </div>
            {showModal && (
                <Modal
                    show={showModal}
                    onClose={handleCloseModal}
                    icon={modalContent.icon}
                    title={modalContent.title}
                    message={modalContent.message}
                />
            )}
        </div>
    );
}

export default ModalPassword;
