import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import '../styles/ModalCloseSesion.scss';
function ModalCloseSesion({ show, onClose, onConfirm }) {
    const handleCancel = () => onClose();
    const handleAccept = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('tokenData');
        window.location.href = '/';
        onClose(); 
        onConfirm();
    };

    return (
        <div className={`modal-close-sesion ${show ? 'show' : ''}`} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <FaExclamationCircle className='iconos-modales' />
                        <h5 className="modal-title">Estás a punto de cerrar sesión</h5>
                    </div>
                    <div className="modal-body">
                        <p>Puedes iniciar sesión de nuevo en cualquier momento.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-cancelar" onClick={handleCancel}>Cancelar</button>
                        <button type="button" className="btn btn-acpetar" onClick={handleAccept}>Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalCloseSesion;
