import "../styles/Navbar.scss";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ModalCloseSesion from './ModalCloseSesion';
import BurgerButton from './BurgerButton';
import { useMediaQuery } from 'react-responsive';

function Navbar() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <React.Fragment>
      {isMobile ? <BurgerButton /> : <DesktopNavbar />}
    </React.Fragment>
  );
}

function DesktopNavbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    setIsLoggedIn(!!userData);
  }, []);

  const scrollToFooter = () => {
    const footer = document.getElementById('footer');
    footer.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLogout = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <nav className="navbar px-5">
      <div className="navbar-center">
        <Link to="/" className="nav-link">Inicio</Link>
        <Link to="/sobrenosotros" className="nav-link">Sobre nosotros</Link>
        <Link to="/" className="nav-link">Casos de éxito</Link>
        <Link to="/" className="nav-link">Blog</Link>
        <Link to="/" className="nav-link" onClick={scrollToFooter}>Contacto</Link>
        {isLoggedIn ? (
          <div className="nav-link logout-icon" onClick={handleLogout}>
            Cerrar sesión
          </div>
        ) : (
          <Link to="/vacantes" className="nav-link">Trabaja con nosotros</Link>
        )}
      </div>
      {showModal && (
        <ModalCloseSesion
          show={showModal}
          onClose={handleCloseModal}
          onConfirm={handleLogout}
        />
      )}
    </nav>
  );
}

export default Navbar;
