import React, { useRef, useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import '../styles/Slider.scss';

import L4 from '../assets/HomePage/Logos/LOGO-AGENCY.png';
import L2 from '../assets/HomePage/Logos/LOGO-ALBA.png';
import L3 from '../assets/HomePage/Logos/LOGO-ALBASTORE.png';
import L1 from '../assets/HomePage/Logos/LOGO-ALCATRAZ.png';
import L5 from '../assets/HomePage/Logos/LOGO-UPPERSIDE.png';
import L6 from '../assets/HomePage/Logos/LOGO-WAK.png';

let images = [
  { src: L1, url: 'https://alcatrazbuilders.com/' },
  { src: L2, url: 'https://albastudio.co/' },
  { src: L5, url: 'https://www.uppersidecol.com/' },
  { src: L4, url: 'https://albagency.co/' },
  { src: L3, url: 'https://albastore.co/' },
  { src: L6, url: 'https://alcatrazbuilders.com/' }
];


const Carrusel = ({ imagesToShow }) => {
  const carruselRef = useRef(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    carruselRef.current.style.transition = 'transform 2s ease-in-out';
  }, [current]);

  const handleLeftClick = () => {
    setCurrent((current) => (current - 1) % images.length);
    const erased = images[images.length - 1];
    images.splice(images.length - 1, 1);
    images.splice(0, 0, erased);
  };

  const handleRightClick = () => {
    setCurrent((current) => (current + 1) % images.length);
    const erased = images[0];
    images.splice(0, 1);
    images.splice(images.length, 1, erased);
  };

  return (
    <div className='Contenedor-Carrusel'>
      <div className="carrusel" ref={carruselRef}>
        {images.slice(0, imagesToShow).map((image, index) => (
          <div className="product" key={`product_${index}`}>
            {/* <a href={image.url} target="_blank" rel="noopener noreferrer"> */}
              <img className='logos' src={image.src} alt={`Product ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="arrow left-arrow" onClick={handleLeftClick}>
        <FaAngleLeft />
      </div>
      <div className="arrow right-arrow" onClick={handleRightClick}>
        <FaAngleRight />
      </div>
    </div>
  );
};

export default Carrusel;
