// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-title {
  font-family: "Titulos";
}

.contenido-modal {
  padding: 20px 20px !important;
}

.parrafo-modal {
  text-align: center !important;
  font-family: "Textos";
}

.modal-footer button {
  margin-top: 20px;
  font-family: "Botones";
  font-size: 1.5rem;
  background-color: #fb7d00;
  border-radius: 50px;
  padding: 11px 35px;
  color: white;
  border-style: none;
}
.modal-footer .btn:hover {
  background-color: #fb7d00 !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;;AACA;EACI,sBAAA;AAEJ;;AACA;EACI,6BAAA;AAEJ;;AACA;EACI,6BAAA;EACA,qBAAA;AAEJ;;AAEI;EACI,gBAAA;EACA,sBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;AACR;AAEM;EACE,oCAAA;AAAR","sourcesContent":[".modal{\n    background-color: rgba(0, 0, 0, 0.5);\n}\n.modal-title{\n    font-family: 'Titulos';\n}\n\n.contenido-modal{\n    padding: 20px  20px !important ;\n}\n\n.parrafo-modal{\n    text-align: center !important;\n    font-family: 'Textos';\n}\n\n.modal-footer {\n    button {\n        margin-top: 20px;\n        font-family: 'Botones';\n        font-size: 1.5rem;\n        background-color: #fb7d00;\n        border-radius: 50px;\n        padding: 11px 35px;\n        color: white;\n        border-style: none;\n      }\n\n      .btn:hover{\n        background-color: #fb7d00 !important;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
