import React from 'react';
import { IoTimerSharp, IoClose } from "react-icons/io5";
import { MdLocationOn } from 'react-icons/md';
import '../styles/VacancyDetailsModal.scss'
import { FaBriefcase, FaCheck } from 'react-icons/fa';
function VacancyDetailsModal({ vacancy, onClose, onApply, alreadyApplied }) {

  return (
    <div className="modal vacancy-modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
      <div className="modal-dialog vacancy-modal-dialog modal-lg">
        <div className="modal-content vacancy-modal-content">
          <IoClose className="vacancy-btn-close" onClick={onClose} />
          <div className="vacancy-modal-body">
            <h5 className="vacancy-modal-title">{vacancy.nombreVacante}</h5>
            <p className='mb-4 vacancy-modal-gris'>Cod. {vacancy.idVacante}</p>
            <div className='d-flex justify-content-between'>
              <span className=' vacancy-modal-gris'><IoTimerSharp className='me-2' />{vacancy.horario}</span>
              <span className=' vacancy-modal-gris'><MdLocationOn className='me-2'/>{vacancy.ciudadVacante}</span>
              <span className=' vacancy-modal-gris'><FaBriefcase className='me-2' />{vacancy.areaEmpresa}</span>
            </div>
            <h6 className='vacancy-modal-subtitle'>Descripción General</h6>
            <p className='mb-4'>{vacancy.descripcionVacante}</p>
            <p><strong>Horario:</strong> {vacancy.horario}</p>
            <p><strong>Lugar de trabajo:</strong> {vacancy.ciudadVacante}</p>
            <p><strong>Tipo de contrato:</strong> {vacancy.tipoContrato}</p>
            <p><strong>Salario:</strong> ${vacancy.salario} con todas las prestaciones de ley + recargos y horas extras.</p>
            <p><strong>Pago:</strong> {vacancy.periodicidadPago}</p>
            <h6 className='vacancy-modal-subtitle'>Requerimientos</h6>
            <p><FaCheck className='me-2 icono' />Educación Mínima: {vacancy.formacionAcademica}</p>
            <p><FaCheck className='me-2 icono' />Experiencia requerida: {vacancy.tiempoExperiencia}</p>
            <p><FaCheck className='me-2 icono' />Edad: {vacancy.rangoEdad}</p>
            {!vacancy.aplicado && !alreadyApplied && (
              <button
                className='vacancy-btn-primary mt-4'
                onClick={onApply} 
              >
                Postularme
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VacancyDetailsModal;
