import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://albaholding.co:7022',
});

axiosInstance.interceptors.request.use(
    (config) => {
        const tokenData = localStorage.getItem('tokenData');
        const token = tokenData ? JSON.parse(tokenData).token : null;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { axiosInstance };
