import React from 'react';
import { Outlet } from 'react-router-dom'; // Importa Outlet de react-router-dom
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet /> {/* Utiliza Outlet para renderizar el componente de la ruta hija */}
      <Footer />
    </>
  );
};

export { Layout };
