import React from 'react';
import '../styles/Footer.scss';
import Logo from '../assets/HomePage/Logos/LOGO-ALBA-HOLDING.png';
import {
    FaWhatsapp,
    FaInstagram,
    FaTiktok,
    FaYoutube
} from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    return (
        <footer id="footer" className="footer-container"> {/* Agregamos el id="footer" aquí */}
            <div className="footer-content">
                <div className="footer-section">
                    <img src={Logo} alt="" className="logo-footer" />
                    <p> <a href="/">Politicas de privacidad</a> y <a href="/">Tratamineto de datos.</a> <br />
                        Preguntas frecuentes
                    </p>
                    <hr className='separador' />
                    <p>Si deseas más informacion sobre tus datos personales, puedes escribirnos al siguiente correo: <a href="/">info@albaholding.co</a></p>
                </div>
                <div className="footer-section">
                    <h4>Trabaja con Nosotros</h4>
                    <p>¿Estás interesad@ en hacer parte de nuestro equipo?</p>
                    <h5>Comunícate con:</h5>
                    <p><a href="mailto:talentohumano@albaholding.co">talentohumano@albaholding.co</a></p>
                </div>
                <div className="footer-section mb-5">
                    <h4 className='opcion'>Siguenos en nuestras redes sociales</h4>
                    <div className='iconos-footer'>
                        <FaInstagram className="icono" />
                        <FaWhatsapp className="icono" />
                        <FaTiktok className="icono" />
                        <FaYoutube className="icono" />
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>
                    &copy; ALBA Holding 2024. Todos los derechos reservados.
                    <br />
                    <span className='negrita'>Todos los modelos que aparecen en este sitio web son mayores de 18 años</span>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
