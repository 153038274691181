import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../styles/SliderFrases.scss';
import icon1 from '../assets/HomePage/Iconos/ICONO-1.png'
import icon2 from '../assets/HomePage/Iconos/ICONO-2.png'
import icon3 from '../assets/HomePage/Iconos/ICONO-4.png'
import icon4 from '../assets/HomePage/Iconos/ICONO-3.png'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';


function SliderFrases() {
  return (
    <Carousel
      interval={5000}
      controls={true}
      indicators={false}
      prevIcon={<div className="custom-prev-icon"><FaAngleLeft /></div>} // Icono para el botón anterior con clase personalizada
      nextIcon={<div className="custom-next-icon"><FaAngleRight /></div>} // Icono para el botón siguiente con clase personalizada
    >
      <Carousel.Item>
        <div className="carousel-cell">
          <img src={icon1} alt="..." className='imagen' />
          <div className="carousel-caption">
            <h3>PASIÓN</h3>
            <p>Disfrutamos darlo todo en todo lo que hacemos,
              convencidos de que siempre logramos los mejores resultados.</p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-cell">
          <img src={icon2} alt="..." />
          <div className="carousel-caption">
            <h3>VALENTÍA</h3>
            <p>Desafiamos nuestros propios límites e ideales,
              enfrentando nuevos desafíos con audacia y determinación.</p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-cell">
          <img src={icon3} alt="..." />
          <div className="carousel-caption">
            <h3>HONESTIDAD</h3>
            <p>Somos sinceros y coherentes, respetamos siempre la verdad,
              fomentando la transparencia y el respeto mutuo. </p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="carousel-cell">
          <img src={icon4} alt="..." />
          <div className="carousel-caption">
            <h3>INNOVACIÓN</h3>
            <p>Buscamos dar de qué hablar
              mediante nuevas ideas que marcan la diferencia.</p>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default SliderFrases;
