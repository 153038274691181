// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/Fonts/IntegralCF/IntegralCF-ExtraBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/Fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/Fonts/Poppins/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/Fonts/Poppins/Poppins-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Titulos";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Textos";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Negrilla";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Botones";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
.iconos-modales {
  font-size: 100px;
  color: #fb7d00;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAGA;EACI,qBAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAIA;EACI,uBAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAKA;EACI,sBAAA;EACA,+DAAA;EACA,mBAAA;EACA,kBAAA;AAHJ;AAMA;EACI,gBAAA;EACA,cAAA;AAJJ","sourcesContent":["@font-face {\n    font-family: 'Titulos';\n    src: url('../assets/Fonts/IntegralCF/IntegralCF-ExtraBold.otf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n\n@font-face {\n    font-family: 'Textos';\n    src: url('../assets/Fonts/Poppins/Poppins-Regular.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Negrilla';\n    src: url('../assets/Fonts/Poppins/Poppins-Bold.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Botones';\n    src: url('../assets/Fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n.iconos-modales{\n    font-size: 100px;\n    color: #fb7d00;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
