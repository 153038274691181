import React, { useRef, useEffect } from 'react';
import '../styles/WebinarModal.scss';

function WebinarModal({ show, onVideoComplete }) {
    const videoRef = useRef(null);
    const videoUrl = 'https://albaholding.s3.amazonaws.com/Home/VIDEO+WEBINAR.mp4';

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('ended', handleVideoEnd);
        }
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, []);

    const handleVideoEnd = () => {
        onVideoComplete();
    };

    return (
        <div className={`webinar-modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
            <div className="webinar-modal__dialog">
                <div className="webinar-modal__content">
                    <div className="webinar-modal__header">
                        <h5 className="webinar-modal__title">Webinar</h5>
                    </div>
                    <div className="webinar-modal__body">
                        <video ref={videoRef} width="100%" controls autoPlay>
                            <source src={videoUrl} type="video/mp4" />
                            El navegador no soporta el video.
                        </video>
                    </div>
                    <div className="webinar-modal__footer">
                        <p className="webinar-modal__message">El video se cerrará automáticamente al finalizar.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebinarModal;