import App from './App';
import React from 'react';
import 'bootstrap/scss/bootstrap.scss';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './components/Auth';
import { BrowserRouter as Router } from 'react-router-dom';

// Utiliza createRoot para renderizar tu aplicación
const root = createRoot(document.getElementById('root'));

// Renderiza tu aplicación dentro del root
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
