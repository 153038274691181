import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Modal.scss';

function Modal({ show, onClose, icon, title, message, redirectUrl, onConfirm }) {
    const navigate = useNavigate();
    const handleClose = () => {
        onClose();
        if (onConfirm) {
            onConfirm();
        } else if (redirectUrl) {
            navigate(redirectUrl); // Si hay URL de redirección, navegar a ella
        }
    };

    return (
        <div className={`modal ${show ? 'show' : ''}`} tabIndex="-1" style={{ display: show ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header d-flex justify-content-center align-items-center">
                        {icon}
                    </div>
                    <div className="modal-body contenido-modal text-center">
                        <h5 className="modal-title mb-2">{title}</h5>
                        <p className='parrafo-modal'>{message}</p>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
