import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('userData');
        const storedToken = localStorage.getItem('tokenData');

        if (storedUser && storedToken) {
            try {
                const decodedUser = JSON.parse(storedUser);
                const parsedToken = JSON.parse(storedToken).token;

                setUser(decodedUser);
                setToken(parsedToken);
            } catch (error) {
                console.error("Error decoding user data or token:", error);
                localStorage.removeItem('userData');
                localStorage.removeItem('tokenData');
            }
        }
    }, []);

    const login = (decodedUser, token) => {
        setUser(decodedUser);
        setToken(token);
        localStorage.setItem('userData', JSON.stringify(decodedUser));
        localStorage.setItem('tokenData', JSON.stringify({ token }));
    };

    const logout = () => {
        setUser(null);
        setToken(null);
        localStorage.removeItem('userData');
        localStorage.removeItem('tokenData');
    };

    return (
        <AuthContext.Provider value={{ user, token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
