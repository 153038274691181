// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BurguerMenu {
  background-color: transparent !important;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  z-index: 5;
}
.BurguerMenu .icon {
  width: 50%;
  font-size: 24px;
  color: white;
}
.BurguerMenu .navbar {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  display: none;
  width: 100%;
}
.BurguerMenu .navbar .nav-link {
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
  padding: 10px;
}
.BurguerMenu.show .navbar {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/styles/BurgerButton.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;AACJ;AAAI;EACE,UAAA;EACA,eAAA;EACA,YAAA;AAEN;AACI;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;AACN;AACM;EACE,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;AACR;AAIM;EACE,cAAA;AAFR","sourcesContent":[".BurguerMenu {\n    background-color: transparent !important; // 1. El fondo debe ser negro\n    cursor: pointer;\n    padding: 10px; // Añadido para espaciar el contenido dentro del botón\n    position: absolute;\n    z-index: 5;\n    .icon {\n      width: 50%; // 2. El icono debe tener un width del 50%\n      font-size: 24px; // Ajusta el tamaño del icono según sea necesario\n      color: white; // Cambia el color del icono según sea necesario\n    }\n  \n    .navbar {\n      position: absolute;\n      top: 100%;\n      left: 0;\n      background-color: white; // Fondo blanco para el menú desplegable\n      display: none; // Inicialmente oculto\n      width: 100%; // Añadido para que ocupe todo el ancho del botón\n  \n      .nav-link {\n        color: black; // Color del texto del menú desplegable\n        text-decoration: none;\n        margin-bottom: 10px;\n        display: block; // Añadido para que los enlaces ocupen todo el ancho del menú\n        padding: 10px; // Añadido para espaciar los enlaces dentro del menú\n      }\n    }\n  \n    &.show {\n      .navbar {\n        display: block; // Mostrar el menú desplegable\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
