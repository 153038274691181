import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'; // Importa el ícono Hamburger
import '../styles/BurgerButton.scss';
import ModalCloseSesion from './ModalCloseSesion'; // Asegúrate de tener este componente en el directorio correcto

function BurgerButton() {
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    setIsLoggedIn(!!userData);
  }, []);

  const handleIconClick = () => {
    setSubmenuVisible(!submenuVisible);
    if (dropdownOpen) setDropdownOpen(false); // Cierra el dropdown si se abre el menú
  };

  const handleLogout = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='BurgerMenu'>
      <div className="icon nav-icon-5" onClick={handleIconClick}>
        <Hamburger toggled={submenuVisible} toggle={setSubmenuVisible} />
      </div>
      {submenuVisible && (
        <nav className={`navbar ${submenuVisible ? 'show' : ''}`}>
          <div className="navbar-center">
            <Link to="/" className="nav-link" onClick={() => setSubmenuVisible(false)}>Inicio</Link>
            <Link to="/sobrenosotros" className="nav-link" onClick={() => setSubmenuVisible(false)}>Sobre nosotros</Link>
            <Link to="/success" className="nav-link" onClick={() => setSubmenuVisible(false)}>Casos de éxito</Link>
            <Link to="/blog" className="nav-link" onClick={() => setSubmenuVisible(false)}>Blog</Link>
            <Link to="/contact" className="nav-link" onClick={() => setSubmenuVisible(false)}>Contácto</Link>
            {isLoggedIn ? (
              <div className="nav-link logout-icon" onClick={handleLogout}>
                Cerrar sesión
              </div>
            ) : (
              <Link to="/vacantes" className="nav-link" onClick={() => setSubmenuVisible(false)}>Trabaja con nosotros</Link>
            )}
          </div>
          {showModal && (
            <ModalCloseSesion
              show={showModal}
              onClose={handleCloseModal}
              onConfirm={() => {
                handleCloseModal();
                localStorage.removeItem('userData'); // Asume que así es como se maneja la sesión
                setIsLoggedIn(false);
              }}
            />
          )}
        </nav>
      )}
    </div>
  );
}

export default BurgerButton;
