import '../styles/WebinarPage.scss';
import { Link } from 'react-router-dom';
import React, { useRef } from 'react';
import Logo from '../assets/WebinarPage/Elementos/LOG2.png';

function WebinarPage() {
    const videoRef = useRef(null); // Ref para el elemento video
    const videoUrl = 'https://albaholding.s3.amazonaws.com/Home/VIDEO+WEBINAR.mp4';
    const isMobile = window.innerWidth <= 768;

    const renderDesktopLayout = () => (
        <>
            <div className='Webinar'>
                <div className='Banner'>
                    <div className='Right'>
                        <img src={Logo} className="Logo" alt="Imagen" />
                        <h1>¡HAGAMOS <br /><span>HISTORIA JUNTOS!</span></h1>
                        <p>Únete a nuestro webinar y conoce más sobre nuestro grupo empresarial y las vacantes que tenemos disponibles para tu perfil.</p>
                    </div>
                </div>
                <div className='Banner2'>
                    <div className='Contenido-banner2'>
                        <hr className='linea mb-4' />
                        <h2>AQUÍ INICIA TU CAMINO <br /> <span> HACIA EL ÉXITO</span></h2>
                        <p><span>En ALBA Holding no te limitamos a un trabajo estático,</span> fomentamos la innovación y te motivamos a explorar tus habilidades y talentos en una atmósfera de exigencia y colaboración.</p>
                        <video controls className='video-webinar' ref={videoRef}>
                            <source src={videoUrl} type="video/mp4" />
                            Tu navegador no soporta el tag de video.
                        </video>
                    </div>
                </div>
                <div className='Banner3'>
                    <div className='left'>
                        <h2>CONOCE NUESTRAS <br /> <span>VACANTES</span></h2>
                        <p><span>En ALBA Holding, convertimos la innovación y la creatividad en elementos fundamentales de nuestra cultura y estilo de vida.</span></p>
                        <p>Por eso, queremos encontrar personas convencidas de que somos nosotros, el talento de la organización, los encargados de lograr lo que nos proponemos.</p>
                        <Link to="/vacantes" className='Link-Aplicar'>
                            <button className='BotonAplicar'>
                                POSTÚLATE AHORA
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='Banner4'>
                    <div className='ContenidoBanner4'>
                        <h1>BIENESTAR <span>INTEGRAL</span></h1>
                        <ul>
                            <li>Creamos un ambiente laboral que refleja nuestro espíritu joven y ambicioso.
                                En este entorno vibrante, la excelencia, la colaboración y la innovación son la norma.
                                Si buscas un lugar donde no solo crecerás profesionalmente, sino donde también te sentirás valorado y apoyado, <span>este es el lugar perfecto para ti.</span></li>
                            <li>Para nosotros, es muy importante contar con los mejores profesionales <span>(como tú),</span> por eso invertimos en la capacitación y crecimiento de todo nuestro personal</li>
                            <li><span>Nos interesa el triunfo de nuestros aliados,</span> y solo así, nos verán satisfechos. Nuestros objetivos siempre estarán alineados con los de nuestros colaboradores</li>
                            <li><span>Trabajamos mientras nos divertimos.</span> Participa en nuestras actividades de esparcimiento e integración, donde tú eres el protagonista.</li>
                            <li>Trabajamos en un ambiente de mucho respeto y compañerismo, con salarios competitivos y <span>permanentes oportunidades de crecimiento profesional.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>)
    const renderMobileLayout = () => (
        <>
            <div className='Webinar'>
                <div className='Banner'>
                    <div className='Right'>
                        <img src={Logo} className="Logo" alt="Imagen" />
                        <h1>¡HAGAMOS <br /><span>HISTORIA JUNTOS!</span></h1>
                        <p>Únete a nuestro webinar y conoce más sobre nuestro grupo empresarial y las vacantes que tenemos disponibles para tu perfil.</p>
                    </div>
                </div>
                <div className='Banner2'>
                    <div className='Contenido-banner2'>
                        <hr className='linea mb-3' />
                        <h2>AQUÍ INICIA TU CAMINO <br /> <span> HACIA EL ÉXITO</span></h2>
                        <p><span>En ALBA Holding no te limitamos a un trabajo estático,</span> fomentamos la innovación y te motivamos a explorar tus habilidades y talentos en una atmósfera de exigencia y colaboración.</p>
                        <video controls className='video-webinar' ref={videoRef}>
                            <source src={videoUrl} type="video/mp4" />
                            Tu navegador no soporta el tag de video.
                        </video>
                    </div>
                </div>
                <div className='Banner3'>
                    <div className='left'>
                        <h2>CONOCE NUESTRAS <br /> <span>VACANTES</span></h2>
                        <p><span>En ALBA Holding vivimos para dejar huella,</span> por eso queremos contar con el mejor talento humano del país.</p>
                        <Link to="/vacantes" className='Link-Aplicar'>
                            <button className='BotonAplicar'>
                                POSTÚLATE AHORA
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='Banner4'>
                    <div className='ContenidoBanner4'>
                        <h1>BIENESTAR <span>INTEGRAL</span></h1>
                        <ul>
                            <li>Creamos un ambiente laboral que refleja nuestro espíritu joven y ambicioso.
                                En este entorno vibrante, la excelencia, la colaboración y la innovación son la norma.
                                Si buscas un lugar donde no solo crecerás profesionalmente, sino donde también te sentirás valorado y apoyado, <span>este es el lugar perfecto para ti.</span></li>
                            <li>Para nosotros, es muy importante contar con los mejores profesionales <span>(como tú),</span> por eso invertimos en la capacitación y crecimiento de todo nuestro personal</li>
                            <li><span>Nos interesa el triunfo de nuestros aliados,</span> y solo así, nos verán satisfechos. Nuestros objetivos siempre estarán alineados con los de nuestros colaboradores</li>
                            <li><span>Trabajamos mientras nos divertimos.</span> Participa en nuestras actividades de esparcimiento e integración, donde tú eres el protagonista.</li>
                            <li>Trabajamos en un ambiente de mucho respeto y compañerismo, con salarios competitivos y <span>permanentes oportunidades de crecimiento profesional.</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )

    return (
        <>
            {isMobile ? renderMobileLayout() : renderDesktopLayout()}
        </>
    );
}

export default WebinarPage;
