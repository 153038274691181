import React, { useState } from 'react';
import { axiosInstance } from '../AxiosConfig';
import Modal from '../components/Modal';
import { FaExclamationCircle, FaCheckCircle } from 'react-icons/fa'; // Asegúrate de importar los íconos
import LogoEscritorio from '../assets/LoginPage/Logo2.png';
import LogoMovil from '../assets/LoginPage/Logo1.png';
import { createPortal } from 'react-dom';
import '../styles/LoginPage.scss';

function RestorePage() {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleEmailChange = (e) => setEmail(e.target.value);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email) {
            setModalContent({
                icon: <FaExclamationCircle className='iconos-modales' />,
                title: "Error",
                message: 'Por favor, ingresa un correo electrónico.'
            });
            setShowModal(true);
            return;
        }
        setIsSubmitting(true);
        try {
            const bodyConsulta = { Usuario: email };
            const response = await axiosInstance.post('/api/UserData/RestoreUserPassword', bodyConsulta);
            if (!response.data) {
                setModalContent({
                    icon: <FaExclamationCircle className='iconos-modales' />,
                    title: "Usuario no encontrado",
                    message: 'El usuario no existe en el sistema.'
                });
            } else {
                setModalContent({
                    icon: <FaCheckCircle className='iconos-modales' />,
                    title: "Correo enviado con éxito",
                    message: `Por favor revisa tu bandeja de entrada del correo ${email} para restablecer tu contraseña.`,
                    redirectUrl: '/login'
                });
            }
        } catch (error) {
            console.error('Error al procesar la solicitud:', error);
            setModalContent({
                icon: <FaExclamationCircle className='iconos-modales' />,
                title: "Error",
                message: 'Hubo un error al procesar tu solicitud. Por favor, inténtalo de nuevo más tarde.'
            });
        } finally {
            setIsSubmitting(false);
            setShowModal(true);
        }
    };
    
    const isMobile = window.innerWidth <= 768;

    return (
        <div className='Login-page'>
            <div className='Card-form'>
                <div className='Login-form'>
                    <img src={isMobile ? LogoMovil : LogoEscritorio} className={isMobile ? 'imagen-login-movil' : 'imagen-login'} alt="Imagen" />
                    <h2>Recuperar Contraseña</h2>
                    <form onSubmit={handleSubmit}>
                        <p>Por favor, ingresa el correo electrónico asociado a tu cuenta. Te enviaremos un enlace para restablecer tu contraseña.</p>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Correo electrónico"
                            value={email}
                            onChange={handleEmailChange}
                            disabled={isSubmitting}
                        />
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                            Enviar
                        </button>
                    </form>
                </div>
            </div>
            {showModal &&
                createPortal(
                    <Modal
                        show={showModal}
                        icon={modalContent.icon}
                        title={modalContent.title}
                        message={modalContent.message}
                        redirectUrl={modalContent.redirectUrl} // Pasar la URL como prop al componente Modal
                        onClose={() => setShowModal(false)}
                    />,
                    document.body
                )
            }
        </div>
    );
}

export default RestorePage;
