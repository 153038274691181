// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.izquierda {
  color: black;
  font-size: 30px;
  cursor: pointer;
}

.derecha {
  color: black;
  font-size: 30px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/SliderCard.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,eAAA;EACA,eAAA;AACJ;;AACA;EACI,YAAA;EACA,eAAA;EACA,eAAA;AAEJ","sourcesContent":[".izquierda{\n    color: black;\n    font-size: 30px;\n    cursor: pointer;\n}\n.derecha{\n    color: black;\n    font-size: 30px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
