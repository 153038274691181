// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.carousel-cell {
  text-align: center;
  padding: 20px;
}

.carousel {
  height: 230px;
}

.carousel-cell img {
  max-width: 100%;
  height: auto;
  margin-bottom: 150px;
}

.carousel-cell h3 {
  margin-top: 10px;
  font-size: 18px;
}

.carousel-cell p {
  font-size: 14px;
  font-family: "Textos";
}

.custom-prev-icon {
  font-size: 30px;
  cursor: pointer;
}

.custom-next-icon {
  font-size: 30px;
  cursor: pointer;
}

/* Media query para pantallas de 768px o menos */
@media (max-width: 768px) {
  .carousel-cell {
    padding: 10px;
    /* Reducir el padding en pantallas más pequeñas */
  }
  .carousel-cell h3 {
    font-size: 20px;
    /* Reducir el tamaño del título */
    font-family: "Titulos";
  }
  .carousel-cell p {
    font-size: 12px;
    /* Reducir el tamaño del párrafo */
  }
}
@media (max-width: 280px) {
  .carousel-cell p {
    font-size: 11px;
    /* Reducir el tamaño del párrafo */
  }
  .carousel-cell h3 {
    font-size: 15px;
  }
  .carousel-cell img {
    margin-bottom: 170px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/SliderFrases.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,kBAAA;EACA,aAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,eAAA;EACA,YAAA;EACA,oBAAA;AAEF;;AACA;EACE,gBAAA;EACA,eAAA;AAEF;;AACA;EACE,eAAA;EACA,qBAAA;AAEF;;AACA;EACE,eAAA;EACA,eAAA;AAEF;;AAAA;EACE,eAAA;EACA,eAAA;AAGF;;AADA,gDAAA;AACA;EACE;IACE,aAAA;IACA,iDAAA;EAIF;EADA;IACE,eAAA;IACA,iCAAA;IACA,sBAAA;EAGF;EAAA;IACE,eAAA;IACA,kCAAA;EAEF;AACF;AACA;EACE;IACE,eAAA;IAEA,kCAAA;EAAF;EAGA;IACE,eAAA;EADF;EAGA;IACE,oBAAA;EADF;AACF","sourcesContent":[".carousel-cell {\n  text-align: center;\n  padding: 20px;\n}\n\n.carousel {\n  height: 230px;\n}\n\n.carousel-cell img {\n  max-width: 100%;\n  height: auto;\n  margin-bottom: 150px;\n}\n\n.carousel-cell h3 {\n  margin-top: 10px;\n  font-size: 18px;\n}\n\n.carousel-cell p {\n  font-size: 14px;\n  font-family: 'Textos';\n}\n\n.custom-prev-icon{\n  font-size: 30px;\n  cursor: pointer;\n}\n.custom-next-icon{\n  font-size: 30px;\n  cursor: pointer;\n}\n/* Media query para pantallas de 768px o menos */\n@media (max-width: 768px) {\n  .carousel-cell {\n    padding: 10px;\n    /* Reducir el padding en pantallas más pequeñas */\n  }\n\n  .carousel-cell h3 {\n    font-size: 20px;\n    /* Reducir el tamaño del título */\n    font-family: 'Titulos';\n  }\n\n  .carousel-cell p {\n    font-size: 12px;\n    /* Reducir el tamaño del párrafo */\n  }\n}\n\n@media (max-width: 280px) {\n  .carousel-cell p {\n    font-size: 11px;\n    // text-align: justify;\n    /* Reducir el tamaño del párrafo */\n  }\n\n  .carousel-cell h3 {\n    font-size: 15px;\n  }\n  .carousel-cell img {\n    margin-bottom: 170px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
