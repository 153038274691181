import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './Auth';

const PrivateRoute = () => {
    const { user, login } = useAuth();
    const userData = localStorage.getItem('userData');
    const tokenData = localStorage.getItem('tokenData');

    if (!user && userData && tokenData) {
        try {
            const decodedUser = JSON.parse(userData);
            const token = JSON.parse(tokenData).token;

            if (decodedUser && token) {
                login(decodedUser, token); // Ensure the user context and token are updated
                return <Outlet />; // User is valid, allow access to the protected route
            }
        } catch (error) {
            localStorage.removeItem('userData'); // Clean up corrupt data
            localStorage.removeItem('tokenData');
            return <Navigate to="/login" />;
        }
    }

    return user && tokenData ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
