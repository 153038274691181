// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* O ajusta a la altura deseada */
  text-align: center;
  color: #333;
}

.not-found-container h1 {
  font-size: 10rem;
  margin: 0;
}

.not-found-container p {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.message {
  font-size: 1rem;
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/styles/NotFoundPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA,EAAA,iCAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEE;EACE,gBAAA;EACA,SAAA;AACJ;;AAEE;EACE,iBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,eAAA;EACA,WAAA;AACJ","sourcesContent":[".not-found-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh; /* O ajusta a la altura deseada */\n    text-align: center;\n    color: #333;\n  }\n  \n  .not-found-container h1 {\n    font-size: 10rem;\n    margin: 0;\n  }\n  \n  .not-found-container p {\n    font-size: 1.5rem;\n    margin: 0.5rem 0;\n  }\n  \n  .message {\n    font-size: 1rem;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
