import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import i1 from '../assets/HomePage/Iconos/ICON-7.png';
import i2 from '../assets/HomePage/Iconos/ICON-8.png';
import i3 from '../assets/HomePage/Iconos/ICON-9.png';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import '../styles/SliderCard.scss';

function SliderCard() {
    const [showControls, setShowControls] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768 && window.innerWidth >= 426) {
                setShowControls(true);
            } else {
                setShowControls(false);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Carousel
            interval={5000}
            controls={showControls}
            indicators={false}
            prevIcon={<div className="izquierda"><FaAngleLeft /></div>} // Icono para el botón anterior con clase personalizada
            nextIcon={<div className="derecha"><FaAngleRight /></div>} // Icono para el botón siguiente con clase personalizada
        >
            <Carousel.Item >
                <div className='Carta1 Carta'>
                    <div className='contenido-carta'>
                        <img src={i1} alt="" className="card-image" />
                        <div className="text-container">
                            <h3>EXPERIENCIA</h3>
                            <p>Somos una empresa legalemente constituida,
                                con más de <span>12 años de experiencia.</span>
                            </p>
                            <p>Solo contratamos de forma directa <br />y a término indefinido</p>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='Carta2 Carta'>
                    <div className='contenido-carta'>
                        <img src={i2} alt="" className="card-image" />
                        <div className="text-container">
                            <h3>PRESENCIA NACIONAL</h3>
                            <p>Contamos con <span>6 sedes propias</span> en las principales ciudades a nivel Nacional</p>
                            <p>Cali (3 sedes) <br /> Bogotá (1 sede) <br /> Medellín (2 sedes)</p>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className='Carta3 Carta'>
                    <div className='contenido-carta'>
                        <img src={i3} alt="" className="card-image" />
                        <div className="text-container">
                            <h3>NUESTRA GENTE</h3>
                            <p>El impulso que mueve y hace posible nuestros negocios.</p>
                            <p><span>Somos más de 800 personas</span> <br />trabajando de forma directa en todas las sedes</p>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default SliderCard;
