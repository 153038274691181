import React, {  useRef } from 'react';
import '../styles/ModalPoliticas.scss';

function ModalPoliticas({ onClose }) {
    const modalBodyRef = useRef(null); // Referencia al cuerpo del modal
    const handleClose = (shouldEnableCheckbox) => {
        onClose(shouldEnableCheckbox); // Llama a la función onClose con el parámetro
    };

    return (
        <div className="modal-politicas">
            <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog modal-xl modal-dialog-scrollable " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Política de Privacidad</h5>
                        </div>
                        <div className="modal-body container-fluid" ref={modalBodyRef}>
                            <h2>Términos y Condiciones de Uso</h2>
                            <p><strong>Última Actualización:</strong> febrero de 2024</p>
                            <p>Te damos la bienvenida al portal del Grupo Empresarial Alba Holding.</p>
                            <p>Los siguientes términos y condiciones rigen el uso del sitio web del Grupo Empresarial Alba Holding (en adelante "Alba Holding") y todos los servicios, productos y contenido ofrecidos a través del sitio. Al acceder o utilizar el sitio web, aceptas cumplir con estos términos y condiciones en su totalidad. Si no estás de acuerdo con alguna parte de estos términos y condiciones, no debes usar nuestro sitio web.</p>
                            <p>Por esta razón, en tu calidad de usuario del portal, te invitamos a leer cuidadosamente la siguiente información:</p>
                            <ol>
                                <li>
                                    <h3>Uso Aceptable</h3>
                                    <p>1.1. Debes usar el sitio web de Alba Holding de manera legal, ética y responsable.</p>
                                    <p>1.2. No debes utilizar nuestro sitio web de ninguna manera que pueda causar daño, perjuicio o interrupción del servicio.</p>
                                </li>
                                <li>
                                    <h3>Propiedad Intelectual</h3>
                                    <p>2.1. Todo el contenido del sitio web, incluidos textos, gráficos, logotipos, imágenes y videos, está protegido por derechos de autor y otras leyes de propiedad intelectual.</p>
                                    <p>2.2. No tienes derecho a copiar, modificar, distribuir, transmitir, mostrar, reproducir, publicar, licenciar o crear trabajos derivados basados en el contenido de este sitio web sin nuestro consentimiento previo por escrito.</p>
                                </li>
                                <li>
                                    <h3>Registro y autorización del uso de datos</h3>
                                    <p>3.1. El acceso al Portal es gratuito, es decir, que no requiere el pago de cargos, ni de inscripciones previas para si vista. La información personal que suministre el usuario del portal para acceder a algún contenido se recibe sólo si es proporcionada voluntaria y conscientemente.</p>
                                    <p>3.2. El usuario acepta y autoriza a Alba Holding para que registre estos datos en sus archivos electrónicos, para ser tratados de conformidad con lo dispuesto en su Política de Privacidad y de Protección de Datos Personales.</p>
                                    <p>3.3. El usuario del portal, como titular de sus datos, puede consultar la Política de Tratamiento de Datos Personales que acepta conocer, en donde se detalla el tratamiento, recolección, almacenamiento, uso y procesamiento de la información recogida, así como los procedimientos de consulta y reclamación que le permitirán hacer efectivos sus derechos al acceso, consulta, rectificación, actualización y supresión de los datos, en los términos que señale la ley.</p>
                                    <p>3.4. En caso de que se solicite información de carácter sensible, el usuario del portal reconoce que su autorización es voluntaria. Al ingresar al Portal y proporcionar la información solicitada, el usuario acepta y autoriza de forma voluntaria el tratamiento de los datos sensibles que se realizará de conformidad con la Política de Tratamiento de Datos Personales de la empresa.</p>
                                    <p>3.5. El contacto y uso de los datos podrá efectuarse desde el mismo momento en que los facilita. Por su parte, con su aceptación, Alba Holding se comprometerá a cuidar la confidencialidad y secreto de los datos, a no ceder ni venderlos ni permitir el acceso por parte de terceros no autorizados. Alba Holding podrá permitir su acceso por parte de terceros aliados bajo estrictas condiciones de confidencialidad y exclusivamente para cumplir con las finalidades descritas en el presente acuerdo, quienes en general darán aplicación a su política de tratamiento de datos y la de la empresa. La anterior autorización estará vigente hasta la expresa oposición del usuario, pudiendo en cualquier momento solicitar a Alba Holding sus derechos de acceso, rectificación, actualización, cancelación y supresión de los datos. Contactarse al correo talentohumano@albaholding.co</p>
                                </li>
                                <li>
                                    <h3>Información sobre menores de edad</h3>
                                    <p>4.1. El Portal está diseñado exclusivamente para personas cuya edad sea de 18 años cumplidos en adelante.</p>
                                    <p>4.2. Si el usuario es menor de edad, debe abstenerse de ingresar y de utilizar el Portal</p>
                                </li>
                                <li>
                                    <h3>Privacidad y Protección de Datos</h3>
                                    <p>5.1. Nos comprometemos a proteger tu privacidad y a cumplir con todas las leyes y regulaciones aplicables de protección de datos en Colombia.</p>
                                    <p>5.2. Para obtener más información sobre cómo recopilamos, utilizamos y protegemos tu información personal, consulta nuestra Política de Privacidad.</p>
                                </li>
                                <li>
                                    <h3>Limitación de Responsabilidad</h3>
                                    <p>6.1. Alba Holding no será responsable de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo que surja del uso o la imposibilidad de usar nuestro sitio web.</p>
                                </li>
                                <li>
                                    <h3>Cambios en los Términos y Condiciones</h3>
                                    <p>7.1. Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento y sin previo aviso. Se te notificarán los cambios mediante la publicación de los términos y condiciones actualizados en el sitio web.</p>
                                </li>
                                <li>
                                    <h3>Ley Aplicable y Jurisdicción</h3>
                                    <p>8.1. Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de la República de Colombia.</p>
                                    <p>8.2. Cualquier disputa relacionada con estos términos y condiciones estará sujeta a la jurisdicción exclusiva de los tribunales competentes en Colombia.</p>
                                </li>
                            </ol>
                        </div>
                        <div className="modal-footer d-flex justify-content-center">
                            <button type="button" className="register-button" onClick={handleClose}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalPoliticas;
