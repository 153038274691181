import React from 'react';
import '../styles/HomePage.scss'
import { Link } from 'react-router-dom'
import { FaAngleRight } from 'react-icons/fa';
import Slider from '../components/SliderMarcas'
import SliderCard from '../components/SliderCard'
import SliderFrases from '../components/SliderFrases';
import i1 from '../assets/HomePage/Iconos/ICON-7.png';
import i2 from '../assets/HomePage/Iconos/ICON-8.png';
import i3 from '../assets/HomePage/Iconos/ICON-9.png';
import video from '../assets/HomePage/VIDEO PÁGINA.mp4'
import Arrow from '../assets/HomePage/Iconos/ARROW.png';
import banner from '../assets/HomePage/IMAGEN-BANNER.png'
import icon1 from '../assets/HomePage/Iconos/ICONO-1.png'
import icon2 from '../assets/HomePage/Iconos/ICONO-2.png'
import icon3 from '../assets/HomePage/Iconos/ICONO-3.png'
import icon4 from '../assets/HomePage/Iconos/ICONO-4.png'
import etiqueta from '../assets/HomePage/Iconos/ICON-5.png'
import Logo from '../assets/HomePage/Logos/LOGO-ALBA-HOLDING.png'
import titulo from '../assets/HomePage/Fondos/TEXTO-TRABAJA.png';
import estrella1 from '../assets/HomePage/Iconos/SPARK-BLANCO.png'
import estrella2 from '../assets/HomePage/Iconos/SPARK-NEGRO.png'

function Banner1() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="banner1">
      {isMobile ? (
        <>
          <div className="row1">
            <img src={Logo} className='imagen1' alt="Imagen" />
            <div className='borde-parrafo'>
              <p>NOS DEFINE <span>CREAR </span>
                <span>COMPAÑIAS</span><br /> HECHAS
                PARA <span>EL ÉXITO.</span>
              </p>
            </div>
            <button className='buton1'>HAGAMOS HISTORIA JUNTOS
              <span className="icono"><FaAngleRight /></span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="left-column">
            <img src={Logo} className='imagen1' alt="Imagen" />
            <div className='borde-parrafo'>
              <p>NOS DEFINE <span>CREAR </span>
                <span>COMPAÑIAS</span> HECHAS
                <br />
                PARA <span>EL ÉXITO.</span>
              </p>
            </div>
            <button className='buton1'>HAGAMOS HISTORIA JUNTOS
              <span className="icono"><FaAngleRight /></span>
            </button>
          </div>
          <div className="right-column">
            <img className='imagen-banner' src={banner} alt="Otra Imagen" />
          </div>
        </>
      )}
    </div>
  );
}


function Banner2() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="banner2">
      {isMobile ? (
        <>
          <div className="row1">
            <div className='d-flex d1'>
              <h2 className='Titulo-arriba'>TALENTO</h2>
              <img src={etiqueta} className='etiqueta ' alt="Imagen" />
            </div>
            <div className='d-flex d2'>
              <h2 className='Titulo-abajo'>SIN ETIQUETAS</h2>
              <img src={estrella2} className='estrella2' alt="Imagen" />
            </div>
            <p className="parrafo-negrita" >En ALBA Holding, la inclusión es más que una palabra: ES UNA REALIDAD.</p>
            <p className='parrafo-pequeño'>Aquí lo que realmente importa es tu talento y tu pasión por  lo que haces.</p>
            <p>No importa de dónde vengas, tu género, religión, edad, raza, orintación sexual o cualquier
              otra característica, <span className="parrafo-negrita">lo que valoramos es tu habilidad para marcar la diferencia.</span>
            </p>
            <button>
              CONOCE MÁS
              <span className="icono"><FaAngleRight /></span>
            </button>
          </div>
          <div className="row2">
            <video controls>
              <source src={video} type="video/mp4" />
              Tu navegador no soporta el tag de video.
            </video>
          </div>
        </>
      ) : (
        <>
          <div className="left-column">
            <video controls>
              <source src={video} type="video/mp4" />
              Tu navegador no soporta el tag de video.
            </video>
          </div>
          <div className="right-column">
            <div className='d-flex d1'>
              <h2 className='Titulo-arriba'>TALENTO</h2>
              <img src={etiqueta} className='etiqueta ' alt="Imagen" />
            </div>
            <div className='d-flex d2'>
              <h2 className='Titulo-abajo'>SIN ETIQUETAS</h2>
              <img src={estrella2} className='estrella2' alt="Imagen" />
            </div>
            <p className="parrafo-negrita" >En ALBA Holding, la inclusión es más que una palabra: ES UNA REALIDAD.</p>
            <p className='parrafo-pequeño'>Aquí lo que realmente importa es tu talento y tu pasión por  lo que haces.</p>
            <p>No importa de dónde vengas, tu género, religión, edad, raza, orintación sexual o cualquier
              otra característica, <span className="parrafo-negrita">lo que valoramos es tu habilidad para marcar la diferencia.</span>
            </p>
            <button>
              CONOCE MÁS
              <span className="icono"><FaAngleRight /></span>
            </button>
          </div>
        </>
      )}
    </div>
  );
}



function Banner3() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="banner3">
      <div className='titulo'>
        <h2>LO QUE <span className='titulo2'>NOS MUEVE</span></h2>
        <img src={estrella1} className='estrella1' alt="Imagen" />
      </div>
      {isMobile ? (
        <SliderFrases />
      ) : (
        // Aquí se renderiza la disposición normal de columnas
        <div className='columns-container'>
          <div className="column">
            <img src={icon1} alt="Imagen 1" />
            <h3>PASIÓN</h3>
            <p>Disfrutamos darlo todo en todo lo que hacemos,
              convencidos de que siempre logramos los mejores resultados.</p>
          </div>
          <div className="column centered-column">
            <img src={Arrow} alt="flecha1" />
          </div>
          <div className="column">
            <img src={icon2} alt="Imagen 2" />
            <h3>VALENTÍA</h3>
            <p>Desafiamos nuestros propios límites e ideales,
              enfrentando nuevos desafíos con audacia y determinación.</p>
          </div>
          <div className="column centered-column">
            <img src={Arrow} alt="flecha2" />
          </div>
          <div className="column">
            <img src={icon3} alt="Imagen 3" />
            <h3>HONESTIDAD</h3>
            <p>Somos sinceros y coherentes, respetamos siempre la verdad,
              fomentando la transparencia y el respeto mutuo. </p>
          </div>
          <div className="column centered-column">
            <img src={Arrow} alt="flecha3" />
          </div>
          <div className="column">
            <img src={icon4} alt="Imagen 4" />
            <h3>INNOVACIÓN</h3>
            <p>Buscamos dar de qué hablar
              mediante nuevas ideas que marcan la diferencia.</p>
          </div>
        </div>
      )}
    </div>
  );
}

function Banner4() {
  const isMobile = window.innerWidth <= 768;
  const imagesToShow = isMobile ? 1 : 5;

  return (
    <div className="banner4">
      <div className='contenido'>
        <h2>NUESTRAS <span className='marcas'>MARCAS</span></h2>
        <img src={estrella2} className='estrella2' alt="Imagen" />
      </div>
      <p>¡Definimos <span className='texto-negrita'>nuestro propósito</span> al crear <br></br>
        empresas diseñadas para <span className='texto-negrita'>alcanzar el éxito!</span></p>

      <Slider imagesToShow={imagesToShow} />
      <button>NUESTRO PORTAFOLIO
        <span className="icono"><FaAngleRight /></span>
      </button>

    </div>
  );
}


function Banner5() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="banner5">
      {isMobile ? (
        <>
          <div className="row1"></div>
          <div className="row2">
            <img src={titulo} className='imagen1' alt="Logo de Trabaja con nosotros" />
            <p className="parrafo-negrita">En ALBA Holding, convertimos la innovación y la creatividad en elementos fundamentales de nuestra cultura y estilo de vida.</p>
            <p>Por eso queremos incluir en nuestro equipo a personas que compartan esta pasión.</p>
            <p>Queremos encontrar personas convencidas de que somos nosotros, el talento de la organización, los encargados de lograr lo que nos proponemos.</p>
            <Link to="/vacantes">
              <button>
                POSTÚLATE AHORA
                <span className="icono"><FaAngleRight /></span>
              </button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="left-column">
            <img src={titulo} className='imagen1' alt="Logo de Trabaja con nosotros" />
            <p className="parrafo-negrita">En ALBA Holding, convertimos la innovación y la creatividad en elementos fundamentales de nuestra cultura y estilo de vida.</p>
            <p>Por eso queremos incluir en nuestro equipo a personas que compartan esta pasión.</p>
            <p>Queremos encontrar personas convencidas de que somos nosotros, el talento de la organización, los encargados de lograr lo que nos proponemos.</p>
            <Link to="/vacantes">
              <button>
                POSTÚLATE AHORA
                <span className="icono"><FaAngleRight /></span>
              </button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}


function Banner6() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="banner6">
      {isMobile ? (
        <>
          <div className="row2">
            <div className='contenido'>
              <h2>MARCA <span className='titulo2'>EMPLEADORA</span></h2>
              <img src={estrella2} className='estrella2' alt="Imagen" />
            </div>
            <p className='parrafo1'>¡Aquí no hay espacio para el aburrimiento, <br />
              convertimos el <span className='parrafo-negrita'>trabajo en pasión y la pasión en diversión!</span> </p>
            <SliderCard />
            <button>INICIA TU INDUCCIÓN <span className="icono"><FaAngleRight /></span></button>
          </div>
        </>
      ) : (
        <>
          <div className='contenido'>
            <h2>MARCA <span className='titulo2'>EMPLEADORA</span></h2>
            <img src={estrella2} className='estrella2' alt="Imagen" />
          </div>
          <p className='parrafo1'>¡Aquí no hay espacio para el aburrimiento, <br />
            convertimos el <span className='parrafo-negrita'>trabajo en pasión y la pasión en diversión!</span> </p>
          <div className="card-container">
            <div className='Carta1 Carta'>
              <div className='contenido-carta'>
                <img src={i1} alt="" className="card-image" />
                <div className="text-container">
                  <h3>EXPERIENCIA</h3>
                  <p>Somos una empresa legalemente constituida,
                    con más de <span>12 años de experiencia.</span>
                  </p>
                  <p>Solo contratamos de forma directa <br />y a término indefinido</p>
                </div>
              </div>
            </div>
            <div className='Carta2 Carta'>
              <div className='contenido-carta'>
                <img src={i2} alt="" className="card-image" />
                <div className="text-container">
                  <h3>PRESENCIA NACIONAL</h3>
                  <p>Contamos con <span>6 sedes propias</span> en las principales ciudades a nivel Nacional</p>
                  <p>Cali (3 sedes) <br /> Bogotá (1 sede) <br /> Medellín (2 sedes)</p>
                </div>
              </div>
            </div>
            <div className='Carta3 Carta'>
              <div className='contenido-carta'>
                <img src={i3} alt="" className="card-image" />
                <div className="text-container">
                  <h3>NUESTRA GENTE</h3>
                  <p>El impulso que mueve y hace posible nuestros negocios.</p>
                  <p><span>Somos más de 800 personas</span> <br />trabajando de forma directa en todas las sedes</p>
                </div>
              </div>
            </div>
          </div>
          <button>INICIA TU INDUCCIÓN <span className="icono"><FaAngleRight /></span></button>
        </>
      )}
    </div>
  );
}



function HomePage() {
  return (
    <>
      <Banner1 />
      <Banner2 />
      <Banner3 />
      <Banner4 />
      <Banner5 />
      <Banner6 />
    </>
  );
}

export default HomePage;
