import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaExclamationCircle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';
import { axiosInstance } from '../AxiosConfig';
import { useAuth } from '../components/Auth';
import Modal from '../components/Modal';
import ModalPassword from '../components/ModalPassword';
import LogoMovil from '../assets/LoginPage/Logo1.png';
import LogoEscritorio from '../assets/LoginPage/Logo2.png';
import '../styles/LoginPage.scss';

function LoginPage() {
    const { login } = useAuth();
    const [showModalPassword, setShowModalPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loginAttempts, setLoginAttempts] = useState({ count: 0, user: '' });
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleForgotPasswordClick = (event) => {
        event.preventDefault();
        setShowModalPassword(true);
    };

    const handleCloseModalPassword = () => {
        setShowModalPassword(false);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const credenciales = {
                user: email,
                pass: password
            };

            const response = await axiosInstance.post('/api/Login/Ingresar', credenciales);

            if (response.status === 200 && response.data.tokenResult && response.data.tokenResult.token) {
                const decodedToken = jwtDecode(response.data.tokenResult.token);

                if (decodedToken.estadobloqueo.toString().toLowerCase() === "true") {
                    setModalContent({
                        icon: <FaExclamationCircle className='iconos-modales' />,
                        title: "Usuario bloqueado",
                        message: "Tu usuario se encuentra bloqueado. Por favor, comunícate al correo albaholding@alcatrazbuilder.com para solicitar el desbloqueo del mismo.",
                    });
                } else {
                    const redirectUrl = '/vacantes';
                    setModalContent({
                        icon: <FaCheckCircle className='iconos-modales' />,
                        title: "Inicio de sesión exitoso",
                        message: "¡Bienvenido de vuelta!",
                        redirectUrl: redirectUrl
                    });

                    login(decodedToken, response.data.tokenResult.token); // Pasar el token al login
                    setLoginAttempts({ count: 0, user: '' });
                }
                setShowModal(true);
            } else {
                handleForgotPasswordClick(event);
            }
        } catch (error) {
            console.error("Error en la solicitud de inicio de sesión:", error);

            if (error.response && error.response.status === 400 && error.response.data === "Debe cambiar la contraseña default antes de continuar.") {
                setShowModalPassword(true);
            } else {
                let attempts = loginAttempts.count;
                let lastUser = loginAttempts.user;

                if (email === lastUser) {
                    attempts++;
                } else {
                    attempts = 1;
                    lastUser = email;
                }

                setLoginAttempts({ count: attempts, user: lastUser });

                if (attempts === 3) {
                    const bloquearUsuarioData = { Usuario: email };
                    axiosInstance.post('/api/UserData/ValidationBlockUser', bloquearUsuarioData)
                        .then(response => {
                            setModalContent({
                                icon: <FaExclamationCircle className='iconos-modales' />,
                                title: "Usuario bloqueado",
                                message: "Has alcanzado el número máximo de intentos. Por favor comunicarse al correo albaholding@alcatrazbuilder.com para solicitar el desbloqueo del mismo.",
                            });
                            setShowModal(true);
                        })
                        .catch(error => {
                            console.error('Error al bloquear usuario:', error);
                            setModalContent({
                                icon: <FaExclamationCircle className='iconos-modales' />,
                                title: "Error",
                                message: "No se pudo procesar tu solicitud. Por favor comunicarse al correo albaholding@alcatrazbuilder.com para solicitar el desbloqueo del mismo.",
                            });
                            setShowModal(true);
                        });
                } else {
                    setModalContent({
                        icon: <FaExclamationCircle className='iconos-modales' />,
                        title: "Inicio de sesión fallido",
                        message: "El correo electrónico o la contraseña son incorrectos. ¡Por favor vuelve a intentarlo!",
                    });
                    setShowModal(true);
                }
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalContent({});
    };

    const isMobile = window.innerWidth <= 768;

    return (
        <div className='Login-page'>
            <div className='Card-form'>
                <div className='Login-form'>
                    <img src={isMobile ? LogoMovil : LogoEscritorio} className={isMobile ? 'imagen-login-movil' : 'imagen-login'} alt="Imagen" />
                    <h2>Inicio de sesión</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type='email'
                            className="form-control"
                            placeholder='Correo electrónico'
                            value={email}
                            onChange={handleEmailChange}
                            required
                        />
                        <div className="col-auto">
                            <div className="input-group mb-2 mt-4">
                                <input
                                    className="form-control"
                                    type={showPassword ? "text" : "password"}
                                    placeholder='Contraseña'
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                />
                                <div className="input-group-prepend">
                                    <div className="input-group-text" onClick={(e) => {
                                        e.preventDefault();
                                        setShowPassword(!showPassword);
                                    }}>{showPassword ? <FaEye /> : <FaEyeSlash />}</div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <Link to='/Restore' className='enlace'>¿Olvidaste tu contraseña?</Link>
                        </div>
                        <div>
                            <p>¿No tienes cuenta? <Link to='/registro' className='enlace'>Regístrate</Link></p>
                        </div>
                        <button type='submit'>Iniciar sesión</button>
                    </form>
                </div>
            </div>
            {showModal && (
                <Modal
                    show={showModal}
                    onClose={handleCloseModal}
                    icon={modalContent.icon}
                    title={modalContent.title}
                    redirectUrl={modalContent.redirectUrl}
                    message={modalContent.message}
                />
            )}
            {showModalPassword && <ModalPassword onClose={handleCloseModalPassword} email={email} />}
        </div>
    );
}

export default LoginPage;
