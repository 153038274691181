import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import LogoEscritorio from '../assets/LoginPage/Logo2.png';
import LogoMovil from '../assets/LoginPage/Logo1.png';
import ModalPoliticas from '../components/ModalPoliticas';
import WebinarModal from '../components/WebinarModal';
import { axiosInstance } from '../AxiosConfig';
import Modal from '../components/Modal';
import { FaCheckCircle, FaTimesCircle, FaExclamationTriangle } from 'react-icons/fa';
import '../styles/RegisterPage.scss';

function RegisterPage() {
    const [showModal, setShowModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [showModalGeneric, setShowModalGeneric] = useState(false);
    const [showWebinarModal, setShowWebinarModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [formFields, setFormFields] = useState({
        fullName: '',
        age: 'No',
        gender: '',
        city: '',
        phoneNumber: '',
        email: '',
        educationLevel: '',
        englishLevel: ''
    });
    const [selectOptions, setSelectOptions] = useState({
        genero: [],
        formacion_academica: [],
        nivel_ingles: [],
        ciudades: []
    });

    useEffect(() => {
        const fetchApiList = async () => {
            try {
                const response = await axiosInstance.get('/api/List/GetListDocuments');
                setSelectOptions({
                    genero: response.data.genero,
                    formacion_academica: response.data.formacion_academica,
                    nivel_ingles: response.data.nivel_ingles
                });
                const ciudadesResponse = await axiosInstance.get('/api/List/GetListCities');
                setSelectOptions(prevState => ({
                    ...prevState,
                    ciudades: ciudadesResponse.data
                }));
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        };
        fetchApiList();
    }, []);

    const handlePrivacyPolicyClick = () => {
        setShowModal(true);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleValidationInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormFields(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCityChange = (e) => {
        const selectedCityId = e.target.value;
        setFormFields({
            ...formFields,
            city: selectedCityId
        });
    };

    const handleAgeChange = (e) => {
        const { value } = e.target;
        setFormFields({
            ...formFields,
            age: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formFields.age === 'Si') {
            const allFieldsCompleted = Object.values(formFields).every(field => field !== '');
            if (allFieldsCompleted && isChecked) {
                setShowWebinarModal(true);
            } else {
                setShowModalGeneric(true);
                setModalContent({
                    icon: <FaExclamationTriangle className='iconos-modales' />,
                    title: "Información Incompleta",
                    message: "Por favor complete todos los campos y acepte los términos.",
                });
            }
        } else {
            setShowModalGeneric(true);
            setModalContent({
                icon: <FaExclamationTriangle className='iconos-modales' />,
                title: "Requisito de Edad",
                message: "Debe ser mayor de edad para registrarse.",
            });
        }
    };

    const handleWebinarComplete = () => {
        setShowWebinarModal(false);
        createUser();
    };

    const createUser = async () => {
        const insertSesion = {
            contrasena: '',
            usuario: formFields.email
        };

        const cleanedFullName = formFields.fullName.trim();
        const cleanedPhoneNumber = formFields.phoneNumber.trim();
        const cleanedEmail = formFields.email.trim();
        const requestData = {
            celular: cleanedPhoneNumber,
            correo_electronico: cleanedEmail,
            id_identidad_genero: formFields.gender,
            nombre_usuario: cleanedFullName,
            id_tipo_documento: 1,
            Perfil: 86,
            UltimoEstudioRealizado: formFields.educationLevel,
            NivelIngles: formFields.englishLevel,
            InsertSesion: insertSesion
        };

        try {
            const usuario = await axiosInstance.get(`/api/UserData/ValidationUsername`, {
                params: { Usuario: formFields.email }
            });
            if (!usuario.data) {
                const response = await axiosInstance.post('/api/UserData/CreateUser', requestData);
                if (response.data.id_usuario !== undefined && response.data.id_usuario !== null && response.data.id_usuario !== '') {
                    const redirectUrl = '/vacantes';
                    setShowConfirmationModal(true);
                    setModalContent({
                        icon: <FaCheckCircle className='iconos-modales' />,
                        title: "Usuario creado con éxito",
                        message: `Por favor revisa tu bandeja de entrada del correo ${formFields.email} para poder ver tus credenciales y poder iniciar sesión.`,
                        redirectUrl: redirectUrl
                    });
                } else {
                    setShowModalGeneric(true);
                    setModalContent({
                        icon: <FaTimesCircle className='iconos-modales' />,
                        title: "Error al crear usuario",
                        message: "Hubo un error al momento de crear el usuario. Por favor revisa los datos ingresados. Si los datos son correctos y no puedes crear el usuario, por favor comunícate al correo albaholding@alcatrazbuilder.com.",
                    });
                }
            } else {
                setShowModalGeneric(true);
                setModalContent({
                    icon: <FaTimesCircle className='iconos-modales' />,
                    title: "Error usuario ya existe",
                    message: `Ya existe un usuario con este correo electronico ${formFields.email}  por favor  realiza el proceso para recuperar contraseña o comunícate al correo albaholding@alcatrazbuilder.com.`,
                });
            }
        } catch (error) {
            console.error('Error creating user:', error);
            setShowModalGeneric(true);
            setModalContent({
                icon: <FaTimesCircle className='iconos-modales' />,
                title: "Error al crear usuario",
                message: "Hubo un error al crear el usuario. Por favor, inténtalo de nuevo más tarde.",
            });
        }
    };

    const isMobile = window.innerWidth <= 768;

    return (
        <div className='Register-page'>
            <div className='Card-register col-12 col-sm-12 col-md-6 col-lg-6'>
                <div className='Register-form'>
                    <img src={isMobile ? LogoMovil : LogoEscritorio} className='register-image' alt="Imagen de registro" />
                    <h2 className='register-title'>Crea tu cuenta</h2>
                    <p className='register-subtitle'>Es rápido y sencillo</p>
                    <form onSubmit={handleSubmit}>
                        <div className='register-table'>
                            <div className='register-column row'>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label htmlFor="fullName">Nombre Completo</label>
                                    <input
                                        type='text'
                                        id="fullName"
                                        name="fullName"
                                        value={formFields.fullName}
                                        onChange={handleValidationInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label htmlFor="age">¿Eres mayor de edad?</label>
                                    <select
                                        id="age"
                                        name="age"
                                        value={formFields.age}
                                        onChange={handleAgeChange}
                                        required
                                        className="form-select"
                                    >
                                        <option value=''>Selecciona</option>
                                        <option value='Si'>Si</option>
                                        <option value='No'>No</option>
                                    </select>
                                </div>
                            </div>
                            <div className='register-column row'>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label className='mt-2'>Género</label>
                                    <select
                                        id="gender"
                                        name="gender"
                                        value={formFields.gender}
                                        onChange={handleInputChange}
                                        required
                                        className="form-select"
                                    >
                                        <option value=''>Selecciona</option>
                                        {selectOptions.genero && selectOptions.genero.map(option => (
                                            <option key={option.IdValorLista} value={option.IdValorLista}>{option.Nombre}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label className='mt-2'>Ciudad</label>
                                    <select
                                        id="city"
                                        name="city"
                                        value={formFields.city}
                                        onChange={handleCityChange}
                                        required
                                        className="form-select"
                                    >
                                        <option value=''>Selecciona</option>
                                        {selectOptions.ciudades && selectOptions.ciudades.map(city => (
                                            <option key={city.id_ciudad} value={city.id_ciudad}>{city.nombreCiudad}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='register-column row'>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label className='mt-2'>Número de celular</label>
                                    <input
                                        type='text'
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formFields.phoneNumber}
                                        onChange={handleValidationInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label className='mt-2'>Correo electrónico</label>
                                    <input
                                        type='email'
                                        id="email"
                                        name="email"
                                        value={formFields.email}
                                        onChange={handleInputChange}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className='register-column row'>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label className='mt-2'>Nivel de formación académica</label>
                                    <select
                                        id="educationLevel"
                                        name="educationLevel"
                                        value={formFields.educationLevel}
                                        onChange={handleInputChange}
                                        required
                                        className="form-select"
                                    >
                                        <option value=''>Selecciona</option>
                                        {selectOptions.formacion_academica && selectOptions.formacion_academica.map(option => (
                                            <option key={option.IdValorLista} value={option.IdValorLista}>{option.Nombre}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='register-column col-12 col-sm-12 col-md-6 col-lg6 col-xl-6'>
                                    <label className='mt-2'>Nivel de inglés</label>
                                    <select
                                        id="englishLevel"
                                        name="englishLevel"
                                        value={formFields.englishLevel}
                                        onChange={handleInputChange}
                                        required
                                        className="form-select"
                                    >
                                        <option value=''>Selecciona</option>
                                        {selectOptions.nivel_ingles && selectOptions.nivel_ingles.map(option => (
                                            <option key={option.IdValorLista} value={option.IdValorLista}>{option.Nombre}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="form-check mt-3 d-flex">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexCheckChecked"
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label mt-1" htmlFor="flexCheckChecked">
                                    Autorizo el tratamiento de mis datos según la <span id="privacyPolicyLink" onClick={handlePrivacyPolicyClick}>política de privacidad</span>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className='register-button mt-4 d-flex'>Enviar Registro</button>
                    </form>
                </div>
            </div>
            {showModal &&
                createPortal(
                    <ModalPoliticas onClose={() => setShowModal(false)} />,
                    document.body
                )
            }
            {showWebinarModal &&
                createPortal(
                    <WebinarModal
                        show={showWebinarModal}
                        onVideoComplete={handleWebinarComplete}
                    />,
                    document.body
                )
            }
            {showConfirmationModal &&
                createPortal(
                    <Modal
                        show={showConfirmationModal}
                        icon={modalContent.icon}
                        title={modalContent.title}
                        message={modalContent.message}
                        redirectUrl={modalContent.redirectUrl}
                        onClose={() => setShowConfirmationModal(false)}
                    />,
                    document.body
                )
            }
            {showModalGeneric &&
                createPortal(
                    <Modal
                        show={showModalGeneric}
                        icon={modalContent.icon}
                        title={modalContent.title}
                        message={modalContent.message}
                        redirectUrl={modalContent.redirectUrl}
                        onClose={() => setShowModalGeneric(false)}
                    />,
                    document.body
                )
            }
        </div>
    );
}

export default RegisterPage;