import React,{ useState, useEffect } from "react";
import "../styles/SobreNosotrosPage.scss";
import Logo from "../assets/HomePage/Logos/LOGO-ALBA-HOLDING.png";
import imagen1 from "../assets/SobreNosotrosPage/Images/imagen1.jpg";
import imagen2 from "../assets/SobreNosotrosPage/Images/imagen2.jpg";
import imagen3 from "../assets/SobreNosotrosPage/Images/Todas.jpg";
import { Link } from 'react-router-dom';

function Banner1() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner1Aboutus">
      {(isMobile || isTablet)?  (
        <>
          <div className="row1">
            <img src={Logo} className="imagen1" alt="Imagen" />
            <div className="parrafoAboutus">
              <p>
                ¡Definimos <span>nuestro </span>
                <span>propósito</span> al crear <br /> empresas diseñadas para
                alcanzar <span>el éxito!</span>
              </p>
            </div>
            <Link to="/vacantes">
              <button className="buton1">¡HAZ PARTE DE NUESTRO EQUIPO!</button>
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="left-column">
            <img src={Logo} className="imagen1" alt="Imagen" />
            <div className="parrafoAboutus">
              <p>
                ¡Definimos <span>nuestro </span>
                <span>propósito</span> al crear <br /> empresas diseñadas para
                alcanzar <span>el éxito!</span>
              </p>
            </div>
            <Link to="/vacantes">
              <button className="buton1">¡HAZ PARTE DE NUESTRO EQUIPO!</button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

function Banner2() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner2Aboutus">
      {(isMobile || isTablet)?  (
        <>
          <div className="row1">
            <div className="parrafoAboutus">
              <p>
                NUESTRA <span>HISTORIA </span>
              </p>
              <p className="texto">
                ALBA Holding nació de la convicción de que el verdadero éxito se
                encuentra en la unión de fuerzas y talentos diversos, con un
                propósito compartido: <span>gestionar empresas diseñadas para el
                éxito, donde cada individuo pueda alcanzar su máximo
                potencial.</span>Somos más que un grupo de empresas; somo una familia,
                unida, movida por la pasión, la valentía, la honestidad y la
                innovación.
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="left-column">
            <div className="parrafoAboutus">
              <p>
                NUESTRA <span>HISTORIA </span>
              </p>
              <p className="texto">
                ALBA Holding nació de la convicción de que el verdadero éxito se
                encuentra en la unión de fuerzas y talentos diversos, con un{" "}
                <br />
                propósito compartido:
                <span>
                  gestionar empresas diseñadas para el éxito, donde cada
                  individuo pueda alcanzar su máximo potencial.
                </span>{" "}
                <br />
                Somos más que un grupo de empresas; somo una familia, unida,
                movida por la pasión, la valentía, la honestidad y la
                innovación.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function Banner3() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner3Aboutus">
      {(isMobile || isTablet)? (
        <>
          <div className="row1">
            <div className="texto_container">
              <p>
                NUESTRA <span>MISIÓN </span>
                <p className="texto">
                  <span>
                    En ALBA Holding, nos comprometemos a ser el motor del éxito
                    empresarial y personal,uniendo fuerzas y talentos diversos
                    para gestionar empresas que inspiran y transforman.
                  </span>{" "}
                  Nuestra historia esta marcada por la valentía, la pasión y la
                  honestidad, y nos esforzamos por crear un entorno donde cada
                  inidividuo pueda alcanzar su máximo potencial, contribuyendo
                  al logro de metas comunes.
                </p>
                <p className="texto">
                  Impulsamos la innovación, fomentamos la colaboracion y
                  cultivamos una cultura de excelencia en todas nuestras
                  empresas y en cada paso que damos. nos dedicamos a generar un
                  impacto positivo en nuestra comunidad y en el mundo
                  empresarial, dejando una huella duradera de éxito y
                  crecimiento
                </p>
              </p>
            </div>
          </div>
          <div className="imagen_mobile"></div>
        </>
      ) : (
        <>
          <div className="left-column">
            <div className="texto_container">
              <p>
                NUESTRA <span>MISIÓN </span>
              </p>
              <p className="texto">
                <span>
                  En ALBA Holding, nos comprometemos a ser el motor del éxito
                  empresarial y personal,uniendo fuerzas y talentos diversos
                  para gestionar empresas que inspiran y transforman.
                </span>{" "}
                Nuestra historia esta marcada por la valentía, la pasión y la
                honestidad, y nos esforzamos por crear un entorno donde cada
                inidividuo pueda alcanzar su máximo potencial, contribuyendo al
                logro de metas comunes.
              </p>
              <p className="texto">
                Impulsamos la innovación, fomentamos la colaboracion y
                cultivamos una cultura de excelencia en todas nuestras empresas
                y en cada paso que damos. nos dedicamos a generar un impacto
                positivo en nuestra comunidad y en el mundo empresarial, dejando
                una huella duradera de éxito y crecimiento
              </p>
            </div>
          </div>
          <div className="right-column"></div>
        </>
      )}
    </div>
  );
}

function Banner4() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner4Aboutus">
      {(isMobile || isTablet)?  (
        <>
          <div className="row1">
            <div className="texto_container">
              <p>
                NUESTRA <span>VISÍON</span>
              </p>
              <p className="texto">
                Nuestra visión en AlBA Holding es trascender los límites de la
                experiencia, transfromando el panorama empresarial u humano a
                nivel global. Nos comprometemos a ser la fuerza motriz detrás
                del crecimiento, el fortalecimiento y la productividad de las
                empresas y personas que forman parte de nuestra familia.
                Visualizamos un futuro donde la innovación y la colaboración
                sean la norma, donde cada individuo alcance su máximo potencial
                y donde nuestras comunidades y el impacto positivo.
              </p>
              <p className="texto">
                <span>
                  En AlBA Holding, aspiramos a dejar una huella imborrable en el
                  mundo empresarial y en la sociedad,inspirando un cambio
                  significativo y duradero que trascienda generaciones.
                </span>
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="right-column">
          </div>
          <div className="left-column">
            <div className="texto_container">
              <p>
                NUESTRA <span>VISÍON</span>
              </p>
              <p className="texto">
                Nuestra visión en AlBA Holding es trascender los límites de la
                experiencia, transfromando el panorama empresarial u humano a
                nivel global. Nos comprometemos a ser la fuerza motriz detrás
                del crecimiento, el fortalecimiento y la productividad de las
                empresas y personas que forman parte de nuestra familia.
                Visualizamos un futuro donde la innovación y la colaboración
                sean la norma, donde cada individuo alcance su máximo potencial
                y donde nuestras comunidades y el impacto positivo.
              </p>
              <p className="texto">
                <span>
                  En AlBA Holding, aspiramos a dejar una huella imborrable en el
                  mundo empresarial y en la sociedad,inspirando un cambio
                  significativo y duradero que trascienda generaciones.
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function Banner5() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner5Aboutus">
      {(isMobile || isTablet)? (
        <>
          <div className="row1">
            <div className="texto_container">
              <p>
                ESTRUCTURA<span> EMPRESARIAL </span>
              </p>
              <p className="texto">
                La estructura empresarial es el pilar fundamental que impulsa
                nuestro éxito y nos permite alcanzar nuestros ambiciosos
                objetivos. Con más de 800 personas comprometidas con nuestra
                misión, nuestra sólida plantilla es un reflejo de nuestra
                dedicación a la excelencia y la eficiencia en cada aspecto de
                nuestras operaciones.
              </p>
              <p className="texto">
                Nuestra estructura empresarial está diseñada cuidadosamente para
                asegurar que cada miembro de nuestro equipo desempeñe un papel
                vital en la consecución de nuestras metas. Con más de 200
                personas ocupando cargos administrativos de diversos niveles de
                responsabilidad, contamos con una fuerza laboral diversa y
                altamente capacitada, lista para enfrentar cualquier desafío con
                determinación y habilidad.
              </p>
              <p className="texto">
                Valoramos la eficiencia y la productividad en todos los niveles
                de nuestra organización. Desde los roles administrativos más
                altos hasta los equipos operativos en el terreno, todos están
                alineados con un enfoque claro en el logro de resultados y en el
                impulso hacia adelante.
              </p>
              <p className="texto">
                Nuestra estructura se caracteriza por líneas claras de
                comunicación y toma de decisiones, lo que nos permite actuar con
                agilidad y adaptarnos rápidamente a un entorno empresarial en
                constante cambio. Fomentamos un ambiente de colaboración y
                trabajo en equipo, donde cada voz es escuchada y cada
                contribución es valorada.
              </p>
              <p className="texto">
                <span>
                  En resumen, nuestra estructura empresarial no es solo un marco
                  organizativo, es el motor que impulsa nuestro crecimiento y
                  éxito continuo. Estamos comprometidos con la excelencia en
                  todo lo que hacemos, y nuestra estructura es el cimiento sobre
                  el cual construimos nuestro futuro.
                </span>
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="left-column">
            <div className="parrafoAboutus">
              <p>
                ESTRUCTURA<span> EMPRESARIAL </span>
              </p>
              <p className="texto">
                La estructura empresarial es el pilar fundamental que impulsa
                nuestro éxito y nos permite alcanzar nuestros ambiciosos
                objetivos. Con más de 800 personas comprometidas con nuestra
                misión, nuestra sólida plantilla es un reflejo de nuestra
                dedicación a la excelencia y la eficiencia en cada aspecto de
                nuestras operaciones.
              </p>
              <p className="texto">
                Nuestra estructura empresarial está diseñada cuidadosamente para
                asegurar que cada miembro de nuestro equipo desempeñe un papel
                vital en la consecución de nuestras metas. Con más de 200
                personas ocupando cargos administrativos de diversos niveles de
                responsabilidad, contamos con una fuerza laboral diversa y
                altamente capacitada, lista para enfrentar cualquier desafío con
                determinación y habilidad.
              </p>
              <p className="texto">
                Valoramos la eficiencia y la productividad en todos los niveles
                de nuestra organización. Desde los roles administrativos más
                altos hasta los equipos operativos en el terreno, todos están
                alineados con un enfoque claro en el logro de resultados y en el
                impulso hacia adelante.
              </p>
              <p className="texto">
                Nuestra estructura se caracteriza por líneas claras de
                comunicación y toma de decisiones, lo que nos permite actuar con
                agilidad y adaptarnos rápidamente a un entorno empresarial en
                constante cambio. Fomentamos un ambiente de colaboración y
                trabajo en equipo, donde cada voz es escuchada y cada
                contribución es valorada.
              </p>
              <p className="texto">
                <span>
                  En resumen, nuestra estructura empresarial no es solo un marco
                  organizativo, es el motor que impulsa nuestro crecimiento y
                  éxito continuo. Estamos comprometidos con la excelencia en
                  todo lo que hacemos, y nuestra estructura es el cimiento sobre
                  el cual construimos nuestro futuro.
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function Banner6() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner6Aboutus">
      {(isMobile || isTablet)? (
        <>
          <div className="row1">
            <div className="texto_container">
              <p>
                NUESTRA <span>FILOSOFÍA </span>
              </p>
              <p className="texto">
                <span>
                  En ALBA Holding, nuestra filosofía empresarial se fundamenta
                  en un sentido constante de urgencia, una motivación intrínseca
                  del equipo y una orientación inquebrantable hacia los
                  resultados.
                </span>{" "}
                comprometemos a enfrentar cada desafío con determinación y
                entusiasmo, impulsados por nuestra pasión compartida por la
                excelencia en todo lo que hacemos.
              </p>
              <p className="texto">
                Creemos firmemente que este compromiso con la calidad y el
                rendimiento es lo que impulsa nuestro éxito y nos distingue en
                el mercado. En cada acción y decisión, buscamos superar
                expectativas y alcanzar nuevos niveles de logro, demostrando así
                nuestro compromiso con la innovación, el crecimiento y la
                excelencia en cada aspecto de nuestras operaciones.
              </p>
            </div>
          </div>
          <div className="imagen_mobile"></div>
        </>
      ) : (
        <>
          <div className="left-column">
            <div className="texto_container">
              <p>
                NUESTRA <span>FILOSOFÍA </span>
              </p>
              <p className="texto">
                <span>
                  En ALBA Holding, nuestra filosofía empresarial se fundamenta
                  en un sentido constante de urgencia, una motivación intrínseca
                  del equipo y una orientación inquebrantable hacia los
                  resultados.
                </span>{" "}
                comprometemos a enfrentar cada desafío con determinación y
                entusiasmo, impulsados por nuestra pasión compartida por la
                excelencia en todo lo que hacemos.
              </p>
              <p className="texto">
                Creemos firmemente que este compromiso con la calidad y el
                rendimiento es lo que impulsa nuestro éxito y nos distingue en
                el mercado. En cada acción y decisión, buscamos superar
                expectativas y alcanzar nuevos niveles de logro, demostrando así
                nuestro compromiso con la innovación, el crecimiento y la
                excelencia en cada aspecto de nuestras operaciones.
              </p>
            </div>
          </div>
          <div className="right-column"></div>
        </>
      )}
    </div>
  );
}

function Banner7() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024 && window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="banner7Aboutus">
      {(isMobile || isTablet)?  (
        <>
          <div className="row1">
            <div className="texto_container">
              <p>
                COMPROMISO <span> SOCIAL </span>
              </p>
              <p className="texto">
                En ALBA Holding, nuestro compromiso social va más allá de
                cumplir con nuestras obligaciones financieras y legales; es un
                compromiso con el bienestar y el desarrollo integral de cada
                individuo que forma parte de nuestra familia empresarial. Nos
                esforzamos por crear un entorno seguro, inclusivo y enriquecedor
                donde todos puedan crecer, fortalecerse y alcanzar sus metas
                personales y profesionales.
              </p>
              <p className="texto">
                Este compromiso se refleja en nuestra atención constante a las
                necesidades de nuestros equipos y sus familias, asegurando que
                se sientan apoyadas y valoradas en todo momento. Creemos en la
                importancia de brindar oportunidades de crecimiento y
                desarrollo, así como en la promoción de una cultura de seguridad
                y bienestar en el trabajo.
              </p>
              <p className="texto">
                Además, estamos comprometidos con el fortalecimiento de
                relaciones sólidas con entidades aliadas. Buscamos activamente
                oportunidades para contribuir al progreso y bienestar social,
                demostrando así nuestro compromiso con el crecimiento y éxito de
                todos los que nos rodean.
              </p>
              <p className="texto">
                <span>
                  En ALBA Holding, nuestro compromiso social con nuestra gente
                  es una parte integral de nuestra identidad empresarial, y nos
                  esforzamos por hacer una diferencia positiva en la vida de
                  quienes se unen a nosotros.
                </span>
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="left-column">
            <div className="parrafoAboutus">
              <p>
                COMPROMISO <span> SOCIAL </span>
              </p>
              <p className="texto">
                En ALBA Holding, nuestro compromiso social va más allá de
                cumplir con nuestras obligaciones financieras y legales; es un
                compromiso con el bienestar y el desarrollo integral de cada
                individuo que forma parte de nuestra familia empresarial. Nos
                esforzamos por crear un entorno seguro, inclusivo y enriquecedor
                donde todos puedan crecer, fortalecerse y alcanzar sus metas
                personales y profesionales.
              </p>
              <p className="texto">
                Este compromiso se refleja en nuestra atención constante a las
                necesidades de nuestros equipos y sus familias, asegurando que
                se sientan apoyadas y valoradas en todo momento. Creemos en la
                importancia de brindar oportunidades de crecimiento y
                desarrollo, así como en la promoción de una cultura de seguridad
                y bienestar en el trabajo.
              </p>
              <p className="texto">
                Además, estamos comprometidos con el fortalecimiento de
                relaciones sólidas con entidades aliadas. Buscamos activamente
                oportunidades para contribuir al progreso y bienestar social,
                demostrando así nuestro compromiso con el crecimiento y éxito de
                todos los que nos rodean.
              </p>
              <p className="texto">
                <span>
                  En ALBA Holding, nuestro compromiso social con nuestra gente
                  es una parte integral de nuestra identidad empresarial, y nos
                  esforzamos por hacer una diferencia positiva en la vida de
                  quienes se unen a nosotros.
                </span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function SobreNosotrosPage() {
  return (
    <>
      <Banner1 />
      <Banner2 />
      <Banner3 />
      <Banner4 />
      <Banner5 />
      <Banner6 />
      <Banner7 />
    </>
  );
}

export default SobreNosotrosPage;
